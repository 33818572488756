import Vue from 'vue';
import Router from 'vue-router';
// Containers
const TheContainer = () => import('@/containers/TheContainer')

// Containers costumer
const TheContainerCostumer = () => import('@/views/costumer/containers/TheContainer')
const DashboardCostumer = () => import('@/views/costumer/DashboardCostumer')
const Tranferencia = () => import('@/views/costumer/Tranferencia')
const ContaBancaria = () => import('@/views/costumer/ContaBancaria')
const Mobile = () => import('@/views/costumer/Mobile')
const Validator = () => import('@/views/costumer/validator/Validator')

// Views
const Dashboard = () => import('@/views/Dashboard')

const Empresas = () => import('@/views/cadastro/empresas/Empresas')
const Equipamentos = () => import('@/views/cadastro/equipamentos/Equipamentos')
const Transacoes = () => import('@/views/financeiro/transacoes/Transacoes')

const TranferenciaMediator = () => import('@/views/pages/admin/Tranferencia')
const ContaBancariaMediator = () => import('@/views/pages/admin/ContaBancaria')

const Login = () => import('@/views/pages/Login')
const AdminLogin = () => import('@/views/pages/admin/Login')

const Password = () => import('@/views/costumer/Password')

const PasswordMediator = () => import('@/views/pages/admin/Password')


Vue.use(Router)

const router = new Router({
  mode: 'hash', // https://router.vuejs.org/api/#mode
  linkActiveClass: 'active',
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes()
})

router.beforeEach((to,from,next)=>{
  const token = localStorage.getItem('user-token');
  if (to.path==='/validacao/cliente'){
    return next()
  } else if (!token || token==='') {
    if (to.path === '/admin/login'){
      return next()
    } else if (to.name !== 'Login'){
      return next({name:'Login'})
    }
  }
  next()
})


export default router;


function configRoutes () {
  if (localStorage.getItem('user-token') && localStorage.getItem('user-data')){
    let user = JSON.parse(localStorage.getItem('user-data'));
    if (user && user.isClient){
      return routerClient();
    }
    return routerAdmin();
  }
  return routerInit();
}

function routerClient(){
  return [
    {
      path: '/login',
      name: 'Login',
      component: Login
    },
    {      
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainerCostumer,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: DashboardCostumer
        },
        {
          path: 'mobile',
          name: 'Mobile',
          component: Mobile
        },
        {
          path: 'contabancaria',
          name: 'Conta Bancária',
          component: ContaBancaria
        },
        {
          path: 'tranferencia',
          name: 'Tranferência',
          component: Tranferencia
        },
        {
          path: 'alterarsenha',
          name: 'Alterar Senha',
          component: Password
        },
      ],
    },
  ]
}

function routerAdmin(){
  return [
    {
      path: '/',
      redirect: '/dashboard',
      name: 'Home',
      component: TheContainer,
      children: [
        {
          path: 'dashboard',
          name: 'Dashboard',
          component: Dashboard
        },       
        {
          path: 'cadastro/empresas',
          name: 'Empresas',
          component: Empresas
        },
        {
          path: 'cadastro/equipamentos',
          name: 'Equipamentos',
          component: Equipamentos
        },
        {
          path: 'financeiro/transacoes',
          name: 'Transacoes',
          component: Transacoes
        },
        {
          path: 'tranferencia',
          name: 'Tranferencia',
          component: TranferenciaMediator
        },
        {
          path: 'contabancaria',
          name: 'ContaBancaria',
          component: ContaBancariaMediator 
        },
        {
          path: 'alterarsenha',
          name: 'Alterar Senha',
          component: PasswordMediator
        },
      ]
    },
    {
      path: '/admin/login',
      name: 'AdminLogin',
      component: AdminLogin,
    }
  ]
}

function routerInit(){
  return [
    {
      path: '/login',
      name: 'Login',
      component: Login
    }, 
    {
      path: '/admin/login',
      name: 'AdminLogin',
      component: AdminLogin
    },
    {
      path: '/validacao/cliente',
      name: 'Validacao',
      component: Validator        
    },
  ]
}


