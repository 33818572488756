import Vue from 'vue';
import App from './App';
import router from './router';
import CoreuiVue from '@coreui/vue';
import { iconsSet as icons } from './assets/icons/icons.js';
import store from './store';
import  VueMask from 'v-mask';
import DiVueMask from 'di-vue-mask';
import VueEllipseProgress from 'vue-ellipse-progress';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faUserSecret, faKey, faRecycle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import VueLoading from 'vuejs-loading-plugin';
import 'core-js/stable';
import "@/assets/global.css";


Vue.directive('uppercase', {
  bind(el, _, vnode) {
    el.addEventListener('keyup', (e) => {
      e.target.value = e.target.value.toUpperCase()
      vnode.componentInstance.$emit('input', e.target.value.toUpperCase())
    })
  }
})

Vue.directive('lowercase', {
  bind(el, _, vnode) {
    el.addEventListener('keyup', (e) => {
      e.target.value = e.target.value.toLowerCase()
      vnode.componentInstance.$emit('input', e.target.value.toLowerCase())
    })
  }
})

Vue.config.performance = true

library.add(faUserSecret);
library.add(faKey);
library.add(faRecycle);
Vue.component('font-awesome-icon', FontAwesomeIcon);
Vue.use(VueEllipseProgress);
Vue.use(DiVueMask);
Vue.use(CoreuiVue);
Vue.use(VueLoading,{text:'Aguarde'});
Vue.prototype.$log = console.log.bind(console);

Vue.use(VueMask);

new Vue({
  el: '#app',
  router,
  store,
  icons,
  template: '<App/>',
  components: {
    App
  }
})
